import React from 'react'
import PropTypes from 'prop-types'
import Cookies from 'universal-cookie'
import { Box, Typography, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import languageConstant from '../../../../constant/LanguageConstant'

const LanguageSubMenu = ({ setCurrentLanguage, top, left }) => {
    const cookies = new Cookies()
    const { i18n } = useTranslation()

    const languageKeys = Object.keys(languageConstant)

    const handleChangeLanguage = (key) => {
        i18n.changeLanguage(key)
        cookies.set('language', key, { path: '/' })
        setCurrentLanguage(languageConstant[key])
    }

    return (
        <>
            <Box
                id="language-submenu"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 100,
                    borderRadius: 2 / 5,
                    position: 'absolute',
                    top: top,
                    left: left,
                    zIndex: 100,
                    backgroundColor: 'white',
                    boxShadow: 2,
                }}
            >
                {languageKeys.map((key, index) => (
                    <Box
                        id={`language-submenu-${index}`}
                        key={key}
                        sx={{
                            color: 'text.primary',
                            textDecoration: 'none',
                            cursor: 'pointer',
                            transition: (theme) => theme.transitions.create(['all', 'transform']),
                            duration: (theme) => theme.transitions.duration.shortest,
                            '&:hover': {
                                backgroundColor: 'action.hover',
                                color: 'secondary.main',
                            },
                        }}
                        onClick={() => {
                            handleChangeLanguage(key)
                        }}
                    >
                        <Box m={1} my={1.5} display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="body1">{languageConstant[key]}</Typography>
                        </Box>
                        {index !== languageKeys.length - 1 && <Divider width="100%" />}
                    </Box>
                ))}
            </Box>
        </>
    )
}

LanguageSubMenu.propTypes = {
    setCurrentLanguage: PropTypes.func.isRequired,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
}

export default LanguageSubMenu
