import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, Button } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'

const HintMessage = () => {
    const { t } = useTranslation()
    const { state } = useLocation()
    const { alertTitle, descriptionText, buttonText, hideButton, bottomHintText, onButtonClick } = state

    return (
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Stack spacing={2} m={4} alignItems="center">
                <ErrorIcon color="warning" fontSize="large" />
                <Typography variant="h3" color="textPrimary" sx={{ fontWeight: 'medium' }} textAlign="center">
                    {t(alertTitle)}
                </Typography>
                {descriptionText && (
                    <Typography variant="h6" color="textPrimary" sx={{ fontWeight: 'regular' }}>
                        {t(descriptionText)}
                    </Typography>
                )}
                {!hideButton && (
                    <Button variant="outlined" fullWidth onClick={onButtonClick}>
                        {t(buttonText)}
                    </Button>
                )}
                <Typography variant="h6" color="textPrimary" sx={{ fontWeight: 'regular' }}>
                    {t(bottomHintText)}
                </Typography>
            </Stack>
        </Box>
    )
}

export default HintMessage
