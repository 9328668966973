const getBaseUrl = (chainName) => {
    let url = 'https://etherscan.io'
    if (chainName) {
        let lowerCaseChainName = chainName.toLocaleLowerCase()
        if (lowerCaseChainName === 'mumbai') {
            url = 'https://mumbai.polygonscan.com'
        }
        if (lowerCaseChainName === 'goerli') {
            url = 'https://goerli.etherscan.io'
        }

        if (lowerCaseChainName === 'polygon') {
            url = 'https://polygonscan.com'
        }
    }
    return url
}

export { getBaseUrl }
