import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { Box, Typography, IconButton } from '@mui/material'
import LanguageIcon from '@mui/icons-material/Language'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LanguageSubMenu from './LanguageSubMenu'
import languageConstant from '../../../../constant/LanguageConstant'

const rootStyle = {
    padding: '10px 8px',
    textDecoration: 'none',
    color: '#575757',
    fontSize: '0.9rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    duration: 150,
}

const hoverStyle = { color: '#516fe3' }

const LanguageDropdown = () => {
    const [isLanguageMenuShown, setIsLanguageMenuShown] = useState(false)
    const [currentLanguage, setCurrentLanguage] = useState('English')
    const cookies = new Cookies()
    const [isMouseIn, setIsMouseIn] = useState(false)

    useEffect(() => {
        const cookieStoreLang = cookies.get('language')
        if (cookieStoreLang) {
            // use cookie store language
            setCurrentLanguage(languageConstant[cookieStoreLang])
        }
    }, [])

    const handleButtonClick = () => {
        setIsLanguageMenuShown(!isLanguageMenuShown)
    }

    return (
        <>
            <Box display="flex" alignItems="center" sx={{ opacity: { xs: 0, sm: 1 } }}>
                <Box
                    id="navbar-language"
                    position="relative"
                    onMouseEnter={() => setIsLanguageMenuShown(true)}
                    onMouseLeave={() => setIsLanguageMenuShown(false)}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        onMouseEnter={() => {
                            setIsMouseIn(true)
                        }}
                        onMouseLeave={() => {
                            setIsMouseIn(false)
                        }}
                        style={{
                            ...rootStyle,
                            ...(isMouseIn ? hoverStyle : null),
                        }}
                    >
                        <Box id="navbar-language-icon" mr={1} display="flex" alignItems="center">
                            <LanguageIcon fontSize="small" />
                        </Box>
                        <Typography id="navbar-language-title">{currentLanguage}</Typography>
                        <ExpandMoreIcon fontSize="small" />
                        {isLanguageMenuShown && (
                            <LanguageSubMenu top={40} left={10} setCurrentLanguage={setCurrentLanguage} />
                        )}
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: {
                        xs: 'flex',
                        sm: 'none',
                    },
                }}
            >
                <IconButton onClick={handleButtonClick}>
                    <LanguageIcon fontSize="small" />
                    {isLanguageMenuShown && (
                        <LanguageSubMenu top={40} left={-60} setCurrentLanguage={setCurrentLanguage} />
                    )}
                </IconButton>
            </Box>
        </>
    )
}

export default LanguageDropdown
