import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Box } from '@mui/material'
import Navbar from './component/page/navbar/Navbar'
import Footer from './component/page/Footer'
import VerifyPage from './component/page/VerifyPage'
import VerifyProcessPage from './component/page/VerifyProcessPage/VerifyProcessPage'
import HintMessage from './component/page/HintMessage'

const theme = createTheme({
    palette: {
        default: {
            main: '#a5a5a5',
        },
        primary: {
            main: '#335bf7',
            dark: '#0233f0',
        },
        secondary: {
            main: '#516fe3',
            light: '#b3c6ff',
        },
        info: {
            main: '#2196f3',
        },
        error: {
            main: '#ff6638',
        },
        warning: {
            main: '#ff9800',
        },
        success: {
            main: '#07e191',
        },
        text: {
            primary: '#575757',
            secondary: '#6a6a6a',
            hint: '#a5a5a5',
        },
        action: {
            hover: '#f3f6ff',
        },
    },
    mixins: {
        toolbar: {
            minHeight: 52,
            '@media (min-width: 0px) and (orientation: landscape)': {
                minHeight: 44,
            },
            '@media (min-width: 600px)': {
                minHeight: 60,
            },
        },
    },
    shadows: [
        'none',
        '0px 3px 2px 0px #bdbdbd90',
        '0px 2px 5px 0px #bdbdbd80',
        '0px 3px 10px 0px #bdbdbd50',
        '0px 0px 30px -15px #bdbdbd80',
        'none',
        'none',
        'none',
        '0px 2px 5px 0px #bdbdbd80', // this is for showing menu paper shadow
    ],
    typography: {
        htmlFontSize: 16,
        fontSize: 14,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: '2rem',
            fontWeight: 600,
            letterSpacing: '0.08em',
            lineHeight: 1.5,
        },
        h2: {
            fontSize: '1.8rem',
            fontWeight: 600,
            letterSpacing: '0.03em',
            lineHeight: 1.5,
        },
        h3: {
            fontSize: '1.5rem',
            fontWeight: 600,
            letterSpacing: '0.03em',
            lineHeight: 1.5,
        },
        h4: {
            fontSize: '1.3rem',
            fontWeight: 600,
            letterSpacing: '0.03em',
            lineHeight: 1.5,
        },
        h5: {
            fontSize: '1.1rem',
            fontWeight: 500,
            letterSpacing: '0.03em',
            lineHeight: 1.5,
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 500,
            letterSpacing: '0.03em',
            lineHeight: 1.8,
        },
        body1: {
            fontSize: '0.9rem',
            fontWeight: 400,
            lineHeight: 1.5,
        },
        body2: {
            fontSize: '0.8rem',
            fontWeight: 400,
            letterSpacing: '0.03em',
            lineHeight: 1.5,
        },
        button: {
            fontSize: '0.9rem',
            fontWeight: 500,
            textTransform: 'capitalize',
            letterSpacing: '0.01em',
        },
    },
})

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Box
                width="100vw"
                height="100vh"
                overflow="auto"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
            >
                <Navbar />
                <Box height="100%" width="100%" overflow="auto">
                    <Routes>
                        <Route path="verify-process" exact element={<VerifyProcessPage />} />
                        <Route path="verify-nft/verify-process" exact element={<VerifyProcessPage />} />
                        {/* <Route path="/:tokenId" element={<VerifyPage />} /> */}
                        <Route path="verify-nft/:tokenId" element={<VerifyPage />} />
                        <Route path="hint" element={<HintMessage />} />
                        <Route
                            path="*"
                            element={
                                <Navigate
                                    to="/hint"
                                    replace={true}
                                    state={{
                                        alertTitle: 'needToSpecifyNftTokenID',
                                        descriptionText: null,
                                        bottomHintText: 'closeThisWindowAndGoBackToAppHint',
                                        hideButton: true,
                                    }}
                                />
                            }
                        />
                    </Routes>
                </Box>
                <Box>
                    <Footer />
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default App
