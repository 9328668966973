import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography, Box, Stack, Button, Link, CardMedia } from '@mui/material'
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'

const PreviewContent = ({ info }) => {
    const { t } = useTranslation()

    const getYoutubeEmbedUrl = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        const match = url.match(regExp)
        console.log('match: ', match)
        if (match && match[2].length === 11) {
            return `https://www.youtube.com/embed/${match[2]}`
        } else {
            return null
        }
    }

    return (
        <Box display="flex" justifyContent="center" flexGrow={1}>
            <Box
                mx={2}
                my={5}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                    width: { xs: '90%', md: '100%' },
                    maxWidth: { xs: 400, md: 500 },
                    p: { xs: 3, md: 0 },
                }}
            >
                {info.previewImageUrl && (
                    <img
                        src={info.previewImageUrl}
                        alt="nft-image"
                        style={{
                            maxHeight: '100%',
                            maxWidth: '100%',
                        }}
                    />
                )}
                {info.previewYoutubeUrl && (
                    <CardMedia
                        component="iframe"
                        width="100%"
                        height="280"
                        frameBorder="0"
                        allowFullScreen
                        src={getYoutubeEmbedUrl(info.previewYoutubeUrl)}
                    />
                )}
                {info.previewAnimationUrl && (
                    <Stack spacing={2} alignItems="center">
                        <Button
                            variant="outlined"
                            size="large"
                            startIcon={<OndemandVideoIcon />}
                            href={info.previewAnimationUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('clickToViewVideo')}
                        </Button>
                        <Link
                            variant="body1"
                            href={info.previewAnimationUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            color="text.secondary"
                        >
                            {info.previewAnimationUrl}
                        </Link>
                    </Stack>
                )}
                {!info.previewImageUrl && info.previewYoutubeUrl === '' && info.previewAnimationUrl === '' && (
                    <Stack spacing={2} alignItems="center">
                        <ImageNotSupportedIcon fontSize="large" color="disabled" />
                        <Typography variant="h6" color="text.hint" sx={{ fontWeight: 'regular' }}>
                            {t('nftHasNoPreview')}
                        </Typography>
                    </Stack>
                )}
            </Box>
        </Box>
    )
}

PreviewContent.propTypes = {
    info: PropTypes.object.isRequired,
}

export default PreviewContent
