import React from 'react'
import { AppBar, Toolbar, Box } from '@mui/material'
import logo from '../../../image/itm-logo.png'
import LanguageDropdown from '../navbar/language/LanguageDropdown'

const Navbar = () => {
    return (
        <AppBar position="unset" color="inherit" sx={{ borderBottom: '2px solid #f3f6ff', boxShadow: 4 }}>
            <Toolbar>
                <Box flexGrow={1}>
                    <img src={logo} alt="itm-logo" width="120" />
                </Box>
                <LanguageDropdown />
            </Toolbar>
        </AppBar>
    )
}

export default Navbar
