import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Cookies from 'universal-cookie'
import en from './en.json'
import tw from './zh-TW.json'

const cookies = new Cookies()

var lng
const cookiesStoreLang = cookies.get('language', { path: '/' })

if (cookiesStoreLang) {
    // use cookie store language
    if (cookiesStoreLang === 'en' || cookiesStoreLang === 'zh-TW') {
        lng = cookiesStoreLang
    } else {
        // is unknow language
        cookies.set('language', 'en', { path: '/' })
        lng = 'en'
    }
} else {
    cookies.set('language', 'en', { path: '/' })
    lng = 'en'
}

const resources = {
    en: {
        translations: en,
    },
    'zh-TW': {
        translations: tw,
    },
}

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: lng,
        fallbackLng: 'en',
        debug: true,
        returnObjects: true,
        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: '.',
        interpolation: {
            escapeValue: false,
        },
    })
