import React from 'react'
import { PropTypes } from 'prop-types'
import { Link, Typography } from '@mui/material'

const EnglishInstr = ({ link }) => {
    return (
        <ul style={{ paddingLeft: 24, marginTop: 8 }}>
            <Typography color="text.secondary" component="li" sx={{ typography: { xs: 'body1', sm: 'h6' }, mt: 1 }}>
                Step1. Go to “Single File Verification” page on{' '}
                <Link href={link} target="_blank" rel="noreferrer">
                    ITM Verification Server
                </Link>
                .
                <br />
                link:{' '}
                <Link href={link} target="_blank" rel="noreferrer">
                    {link}
                </Link>
            </Typography>
            <Typography color="text.secondary" component="li" sx={{ typography: { xs: 'body1', sm: 'h6' }, mt: 1 }}>
                Step2. Upload <b>the file you attested</b> via ITM BNS.
            </Typography>
            <Typography color="text.secondary" component="li" sx={{ typography: { xs: 'body1', sm: 'h6' }, mt: 1 }}>
                Step3. Upload its corresponding <b>ITM Proof Token</b> whose extension file name is either “json” or
                “itm”.
            </Typography>
            <Typography color="text.secondary" component="li" sx={{ typography: { xs: 'body1', sm: 'h6' }, mt: 1 }}>
                Step4. Click on “VERIFY” button.
            </Typography>
            <Typography color="text.secondary" component="li" sx={{ typography: { xs: 'body1', sm: 'h6' }, mt: 1 }}>
                Step5. Check the result in the table on the same page. You can check the on-chain proof and see the
                detail.
            </Typography>
        </ul>
    )
}

EnglishInstr.propTypes = {
    link: PropTypes.string.isRequired,
}

export default EnglishInstr
