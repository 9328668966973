import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Button, Link, Stack } from '@mui/material'
import EnglishInstr from './EnglishInstr'
import ChineseInstr from './ChineseInstr'

const verificationLink = 'https://verification.itrustmachines.com/'

const VerifyProcessPage = () => {
    const { i18n, t } = useTranslation()

    const [img, setImg] = useState(null)
    const [mobileImg, setMobileImg] = useState(null)

    useEffect(() => {
        if (i18n.language === 'zh-TW') {
            import('../../../image/processZh.png').then((img) => {
                setImg(img.default)
            })
            import('../../../image/processZhMobile.png').then((img) => {
                setMobileImg(img.default)
            })
        } else {
            import('../../../image/processEn.png').then((img) => {
                setImg(img.default)
            })
            import('../../../image/processEnMobile.png').then((img) => {
                setMobileImg(img.default)
            })
        }
    }, [t])

    const renderInstr = () => {
        if (i18n.language === 'zh-TW') {
            return <ChineseInstr link={verificationLink} />
        } else {
            return <EnglishInstr link={verificationLink} />
        }
    }

    const onBackClick = () => {
        history.back()
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" sx={{ backgroundColor: 'white' }}>
            <Box mt={6} mx={2} textAlign="center">
                <Typography color="textPrimary" sx={{ typography: { sm: 'h1', xs: 'h2' } }}>
                    {t('verifyProcessTitle')}
                </Typography>
                <Typography color="textPrimary">{t('verifyProcessDetail')}</Typography>
            </Box>
            <Link
                href={verificationLink}
                target="_blank"
                rel="noreferrer"
                sx={{ display: 'flex', justifyContent: 'center' }}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    maxWidth={1500}
                    sx={{ '@media screen and (max-width: 750px)': { display: 'none' } }}
                >
                    <img src={img} alt="verify-process" width="100%" height="auto" />
                </Box>
                <Box
                    sx={{
                        '@media screen and (min-width: 751px)': { display: 'none' },
                        width: { xs: '100%', sm: '85%' },
                    }}
                >
                    <img src={mobileImg} alt="verify-process-mobile" width="100%" height="auto" />
                </Box>
            </Link>
            <Box m={3} mr={2}>
                <Typography color="text.primary" sx={{ fontWeight: 600, typography: { xs: 'h5', sm: 'h4' } }}>
                    {t('verifyInstructions')}
                    {t('colon')}
                </Typography>
                {renderInstr()}
            </Box>
            <Stack spacing={2} mb={10}>
                <Button href={verificationLink} target="_blank" rel="noreferrer" variant="contained" disableElevation>
                    {t('goTo')} ITM {t('verificationServer')}
                </Button>
                <Button variant="outlined" disableElevation onClick={onBackClick}>
                    {t('back')}
                </Button>
            </Stack>
        </Box>
    )
}

export default VerifyProcessPage
