import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './css/style.css'
import App from './App'
import './i18n/i18n'
import reportWebVitals from './reportWebVitals'

if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
)

reportWebVitals()
