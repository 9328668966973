import React from 'react'
import { Box, Typography } from '@mui/material'

const Footer = () => {
    return (
        <Box maxHeight={55} backgroundColor="#fff" sx={{ borderTop: '1px solid #e7edfe' }} width="100%">
            <Typography variant="body1" color="text.primary" sx={{ p: 2 }}>
                © ITM 2023
            </Typography>
        </Box>
    )
}

export default Footer
