import React from 'react'
import { PropTypes } from 'prop-types'
import { Link, Typography } from '@mui/material'

const ChineseInstr = ({ link }) => {
    return (
        <ul style={{ paddingLeft: 24, marginTop: 8 }}>
            <Typography color="text.secondary" component="li" sx={{ typography: { xs: 'body1', sm: 'h6' }, mt: 1 }}>
                步驟1. 開啟
                <Link href={link} target="_blank" rel="noreferrer">
                    ITM公開驗證服務
                </Link>
                之單一檔案驗證功能。
                <br />
                公開驗證服務網址：
                <Link href={link} target="_blank" rel="noreferrer">
                    {link}
                </Link>
            </Typography>
            <Typography color="text.secondary" component="li" sx={{ typography: { xs: 'body1', sm: 'h6' }, mt: 1 }}>
                步驟2. 將「<b>已透過BNS存證之檔案</b>」上傳。
            </Typography>
            <Typography color="text.secondary" component="li" sx={{ typography: { xs: 'body1', sm: 'h6' }, mt: 1 }}>
                步驟3. 將已存證檔案對應之「<b>ITM Proof Token</b>」上傳。
            </Typography>
            <Typography color="text.secondary" component="li" sx={{ typography: { xs: 'body1', sm: 'h6' }, mt: 1 }}>
                步驟4. 點擊驗證。
            </Typography>
            <Typography color="text.secondary" component="li" sx={{ typography: { xs: 'body1', sm: 'h6' }, mt: 1 }}>
                步驟5. 查看驗證結果。
            </Typography>
        </ul>
    )
}

ChineseInstr.propTypes = {
    link: PropTypes.string.isRequired,
}

export default ChineseInstr
