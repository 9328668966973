const envType = process.env.REACT_APP_CHAIN || 'MAINNET'

const getChainName = (chainType) => {
    let chainName = chainType
    if (envType.toLocaleLowerCase() === 'testnet') {
        if (chainType.toLocaleLowerCase() === 'polygon') {
            chainName = 'MUMBAI'
        } else {
            chainName = 'GOERLI'
        }
    }
    return chainName
}

export { getChainName }
