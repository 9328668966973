import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography, Divider, Paper, Box, IconButton, Collapse, Stack, Button, CircularProgress } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/system'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import moment from 'moment'
import KdanIcon from '../../image/kdan_logo.png'
import PreviewContent from './PreviewContent'
import statusConstant from '../../constant/StatusConstant'
import axios from '../../action/MintServerApi'
import { getBaseUrl } from '../../util/urlUtil'
import { getChainName } from '../../util/envUtil'

const contentStyle = { wordBreak: 'break-all', fontWeight: 'regular' }
const buttonStyle = { padding: '6px 4px', width: 'fit-content', justifyContent: 'flex-start' }

const ButtonLink = styled(Button)(() => buttonStyle)

const VerifyPage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    let { tokenId } = useParams()
    const [loadingBtn, setLoadingBtn] = useState({ rawData: false, proof: false })
    const [isPending, setIsPending] = useState(false)
    const [isExpand, setIsExpand] = useState(true)
    const [status, setStatus] = useState(statusConstant.completeAttest)
    const [info, setInfo] = useState({
        previewImageUrl: '',
        previewYoutubeUrl: '',
        previewAnimationUrl: '',
        nftName: '',
        accountAddress: '',
        attestationTime: '',
        cmd: '',
        clearanceOrder: '',
        indexValue: '',
        txHash: '',
        mintedEnvSet: [],
        bitIntId: 0,
    })
    const [fileInfo, setFileInfo] = useState({
        downloadFileUrl: '',
        fileName: '',
    })
    const [proofInfo, setProofInfo] = useState({
        downloadProofTokenUrl: '',
        proofTokenName: '',
    })

    const serverChain = process.env.REACT_APP_CHAIN || 'MAINNET'
    var etherscanUrlPrefix =
        serverChain.toLocaleLowerCase() === 'mainnet' ? 'https://etherscan.io' : 'https://goerli.etherscan.io'
    const attestTxUrl = `${etherscanUrlPrefix}/tx/${info.txHash}`
    const attestContractDetailUrl = `${etherscanUrlPrefix}/address/${process.env.REACT_APP_ATTEST_CONTRACT_ADDRESS}`

    const nftContent = [
        { title: t('nftName'), text: info.nftName },
        { title: t('fileName'), text: fileInfo.fileName },
    ]

    const fetchBasicInfo = () => {
        return axios.get(`/rest/nft/verify-page/${tokenId}`)
    }

    const fetchFileInfo = () => {
        return axios.get(`/rest/nft/download/raw-data/${tokenId}`)
    }

    const fetchProofTokenInfo = () => {
        return axios.get(`/rest/nft/download/proof-token/${tokenId}`)
    }

    useEffect(() => {
        setIsPending(true)
        console.log('tokenId: ', tokenId)
        if (tokenId && tokenId.length > 0) {
            Promise.all([fetchBasicInfo(), fetchFileInfo(), fetchProofTokenInfo()])
                .then((results) => {
                    console.log('results: ', results)
                    const infoRes = results[0]
                    const fileInfoRes = results[1]
                    const proofInfoRes = results[2]

                    const {
                        attestedStatus,
                        image,
                        youtubeUrl,
                        animationUrl,
                        name,
                        account,
                        attestationTime,
                        cmd,
                        clearanceOrder,
                        indexValue,
                        txHash,
                        mintedEnvSet,
                    } = infoRes.data

                    switch (attestedStatus) {
                        case 'ON_CHAIN':
                            setStatus(statusConstant.completeAttest)
                            break
                        case 'ATTESTED':
                            setStatus(statusConstant.pendingAttest)
                            break
                        case 'NON_ATTESTED':
                            setStatus(statusConstant.notAttest)
                            break
                    }

                    setInfo({
                        previewImageUrl: image,
                        previewYoutubeUrl: youtubeUrl,
                        previewAnimationUrl: animationUrl,
                        nftName: name,
                        accountAddress: account,
                        attestationTime: attestationTime,
                        cmd: cmd,
                        clearanceOrder: clearanceOrder,
                        indexValue: indexValue,
                        txHash: txHash,
                        mintedEnvSet: mintedEnvSet,
                        bigIntId: BigInt('0x' + tokenId, 16),
                    })

                    setFileInfo({
                        downloadFileUrl: fileInfoRes.data.downloadUrl,
                        fileName: fileInfoRes.data.fileName,
                    })

                    setProofInfo({
                        downloadProofTokenUrl: proofInfoRes.data.downloadUrl,
                        proofTokenName: proofInfoRes.data.fileName,
                    })
                    setIsPending(false)
                })
                .catch((error) => {
                    console.log('error: ', error)
                    if (error.response.status === 404) {
                        handleNavigateToHint({
                            alertTitle: 'nftNotExist',
                            descriptionText: null,
                            hideButton: true,
                            bottomHintText: 'closeThisWindowAndGoBackToAppHint',
                        })
                    } else {
                        handleNavigateToHint({
                            alertTitle: 'somethingWentWrong',
                            descriptionText: null,
                            hideButton: true,
                            bottomHintText: 'closeThisWindowAndGoBackToAppHint',
                        })
                    }
                })
        }
    }, [])

    const handleNavigateToHint = (state) => {
        navigate('/hint', {
            replace: true,
            state: state,
        })
    }

    const renderTime = (timestamp) => {
        return moment(timestamp).format('YYYY/MM/DD HH:mm:ss')
    }

    const handleExpandClick = () => {
        setIsExpand((prev) => !prev)
    }

    const fetchDownloadUrl = (url, name) => {
        fetch(url, {
            method: 'GET',
        })
            .then((res) => {
                return res.blob()
            })
            .then((blob) => {
                let url = URL.createObjectURL(blob)
                let link = document.createElement('a')
                link.href = url
                link.download = name
                link.target = '_blank'
                document.body.appendChild(link)
                console.log('link: ', link)
                link.click()
                link.remove()
                setLoadingBtn({ rawData: false, proof: false })
            })
            .catch((error) => {
                console.error(error)
                setLoadingBtn({ rawData: false, proof: false })
            })
    }

    const onDownloadProofClick = () => {
        const { downloadProofTokenUrl, proofTokenName } = proofInfo
        fetchDownloadUrl(downloadProofTokenUrl, proofTokenName)
        setLoadingBtn({ rawData: false, proof: true })
    }

    const handleNavigate = () => {
        navigate('/verify-nft/verify-process')
    }

    const renderNftMintInfo = (mintedEnvSet, bigIntId) => {
        return mintedEnvSet.map((backendChainType) => {
            let chainName = getChainName(backendChainType)
            let baseUrl = getBaseUrl(chainName)
            let contractAddress = backendChainType.toLocaleLowerCase().includes('polygon')
                ? process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS
                : process.env.REACT_APP_ETH_CONTRACT_ADDRESS
            return (
                <Stack key={backendChainType} spacing={1}>
                    <Typography variant="h6" color="text.primary">
                        {`${t(chainName)} ${t('nftInfo')}`}
                    </Typography>
                    <Typography variant="body1" color="text.primary">
                        {t('nftContractAddress')}
                    </Typography>
                    <Typography
                        component="a"
                        variant="body1"
                        color="text.primary"
                        href={`${baseUrl}/address/${contractAddress}`}
                        target="_blank"
                        rel="noreferrer noopener"
                        sx={{ wordBreak: 'break-all' }}
                    >
                        {contractAddress}
                    </Typography>
                    <ButtonLink
                        variant="text"
                        href={`${baseUrl}/token/${contractAddress}?a=${bigIntId}`}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {`${t('clickToViewMintTransaction')}(${t(chainName)})`}
                    </ButtonLink>
                </Stack>
            )
        })
    }

    return (
        <>
            {isPending ? (
                <Box m={4} display="flex" justifyContent="center">
                    <CircularProgress size={30} />
                </Box>
            ) : (
                <Box display="flex" height="100%" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                    <Paper elevation={0} sx={{ px: 3, py: 2, display: { xs: 'flex', md: 'none' } }}>
                        <Typography variant="h4" color="text.primary">
                            {status === statusConstant.notAttest ? t('nftDetail') : t('nftDetailAndVerification')}
                        </Typography>
                    </Paper>
                    <PreviewContent info={info} />
                    <Box
                        sx={{
                            bgcolor: 'white',
                            px: 3,
                            py: 2,
                            minWidth: 300,
                            maxWidth: { md: 450 },
                            overflow: { md: 'auto' },
                        }}
                    >
                        <Typography variant="h4" color="text.primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
                            {status === statusConstant.notAttest ? t('nftDetail') : t('nftDetailAndVerification')}
                        </Typography>
                        <Stack spacing={2} mt={1} divider={<Divider />} sx={{ flexGrow: 1 }}>
                            {status !== statusConstant.notAttest && (
                                <>
                                    <Box>
                                        <Typography variant="h6" color="text.primary">
                                            {t('attestedStatus')}
                                        </Typography>
                                        {status === statusConstant.completeAttest && (
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <CheckCircleIcon color="success" fontSize="small" />
                                                <Typography variant="h6" color="success.main">
                                                    {t('complete')}
                                                </Typography>
                                            </Box>
                                        )}
                                        {status === statusConstant.pendingAttest && (
                                            <>
                                                <Box display="flex" alignItems="center" gap={1} mb={1}>
                                                    <HourglassBottomIcon fontSize="small" sx={{ color: 'text.hint' }} />
                                                    <Typography variant="h6" color="text.hint">
                                                        {t('pending')}
                                                    </Typography>
                                                </Box>
                                                <Typography variant="body1" color="text.hint">
                                                    {t('pendingAttestHint')}
                                                </Typography>
                                            </>
                                        )}
                                    </Box>
                                </>
                            )}
                            <Stack spacing={1}>
                                {!info.mintedEnvSet ||
                                    (info.mintedEnvSet.length === 0 && (
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <ErrorOutlineIcon fontSize="small" color="warning" />
                                            <Typography variant="h6" color="warning.main">
                                                {t('nftNotMinted')}
                                            </Typography>
                                        </Box>
                                    ))}
                                {nftContent.map((content) => (
                                    <Box key={content.title}>
                                        <Typography variant="h6" color="text.primary">
                                            {content.title}
                                        </Typography>
                                        <Typography variant="h6" color="text.primary" sx={contentStyle}>
                                            {content.text}
                                        </Typography>
                                    </Box>
                                ))}
                                {info.mintedEnvSet &&
                                    info.mintedEnvSet.length > 0 &&
                                    renderNftMintInfo(info.mintedEnvSet, info.bigIntId)}
                            </Stack>

                            <Stack spacing={2}>
                                <Box>
                                    <Typography variant="h6" color="text.primary">
                                        {t('walletAddress')}
                                    </Typography>
                                    <Typography variant="h6" color="text.primary" sx={contentStyle}>
                                        {info.accountAddress}
                                    </Typography>
                                </Box>
                                {status !== statusConstant.notAttest && (
                                    <>
                                        <Box>
                                            <Typography variant="h6" color="text.primary">
                                                {t('attesterCertificatedInfo')}
                                            </Typography>
                                            <Box display="flex" alignItems="center" gap={2} mt={1}>
                                                <img src={KdanIcon} alt="kdan-icon" width={50} height={28} />
                                                <Box>
                                                    <Typography
                                                        variant="h6"
                                                        color="text.primary"
                                                        sx={{ ...contentStyle, lineHeight: 1.5 }}
                                                    >
                                                        凱鈿行動科技股份有限公司
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        color="text.primary"
                                                        sx={{ ...contentStyle, lineHeight: 1.5 }}
                                                    >
                                                        Kdan Mobile Software Ltd.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography variant="h6" color="text.primary">
                                                {t('attestationTime')}
                                            </Typography>
                                            <Typography variant="h6" color="text.primary" sx={contentStyle}>
                                                {renderTime(info.attestationTime)}
                                            </Typography>
                                        </Box>
                                    </>
                                )}
                                {status === statusConstant.completeAttest && (
                                    <>
                                        <Box>
                                            <Typography variant="h6" color="text.primary">
                                                {t('attestContractAddress')}
                                            </Typography>
                                            <Typography variant="h6" color="text.primary" sx={contentStyle}>
                                                {process.env.REACT_APP_ATTEST_CONTRACT_ADDRESS}
                                            </Typography>
                                        </Box>
                                        <Stack>
                                            <ButtonLink
                                                href={attestContractDetailUrl}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                underline="none"
                                            >
                                                {t('clickToViewContractDetail')}
                                            </ButtonLink>
                                            <ButtonLink
                                                href={attestTxUrl}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                underline="none"
                                            >
                                                {t('clickToViewBlockchainTransaction')}
                                            </ButtonLink>
                                        </Stack>
                                    </>
                                )}
                            </Stack>
                            {status === statusConstant.completeAttest && (
                                <Stack>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="h6" color="text.primary" sx={{ mr: 1 }}>
                                            {t('historyProperties')}
                                        </Typography>
                                        <IconButton size="small" onClick={handleExpandClick}>
                                            {isExpand ? (
                                                <ExpandLessIcon fontSize="small" />
                                            ) : (
                                                <ExpandMoreIcon fontSize="small" />
                                            )}
                                        </IconButton>
                                    </Box>
                                    <Collapse in={isExpand}>
                                        <Typography variant="h6" color="text.primary" sx={contentStyle}>
                                            {info.cmd}
                                        </Typography>
                                    </Collapse>
                                </Stack>
                            )}
                            {status === statusConstant.completeAttest && (
                                <Stack>
                                    <LoadingButton
                                        variant="text"
                                        loading={loadingBtn['proof']}
                                        onClick={onDownloadProofClick}
                                        sx={buttonStyle}
                                    >
                                        {t('downloadTheITMProofToken')}
                                    </LoadingButton>
                                    <ButtonLink onClick={handleNavigate}>{t('viewTheDetailVerifyStep')}</ButtonLink>
                                </Stack>
                            )}
                        </Stack>
                    </Box>
                </Box>
            )}
        </>
    )
}

export default VerifyPage
